export default {
  version: "v1.9.7", //current version
  availableLanguages: ["en", "pt", "es", "fr"], // first index is the default langauge
  isDebug: false, // is development mode activated. All console messages area affected.
  isBetaFeature: false, // show components in betaStage
  hasAudit: true, // App auditing
  culture: "pt-PT", // culture to be used in decimal values
  currency: "EUR", // curreny key for monetary values
  email: "geral@plasticos-futura.com", // bottom information
  email2: "comercial4@plasticos-futura.com", // Technical email
};